import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { selectedCat } from '../../store/actions/categoryActions';
import { useTranslation } from 'react-i18next';

function Categories({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate()

const {t} = useTranslation();
  const onSubmit = (cat) => {
    dispatch(selectedCat(cat, navigate))
  }

  console.log(category?.language)
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       <h2 className='quicklineminititle'>{t('Choose your Category')}</h2>
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {Array.isArray(category?.categories) && category?.categories?.map((cat) => {
        return (
          <Button onClick={() => onSubmit(cat?._id)} variant="outline-primary" key={cat?._id} style={{width: 250, marginTop: 20}}>{category.language === 'english' ? cat?.name : category?.language === 'kiswahili' ?  (cat?.nameswahili || cat?.name): cat?.name}</Button>
        )
      })}

       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Categories;
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Dropdown, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { selectedCat } from '../../store/actions/categoryActions';
import mpesaimg from '../../assets/images/mpesa.png';
import tigopesaimg from '../../assets/images/tigopesa.jpeg';
import airtelmoneyimg from '../../assets/images/airtelmoney.jpeg';
import halopesa from '../../assets/images/halopesa.png';
import moment from 'moment';
import generatePDF from 'react-to-pdf';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function Successscreen({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
  const targetRef = useRef();
  const {t} = useTranslation()
  const navigate = useNavigate();
  const onSubmit = () => {
    window.location.replace('/qrcode')
  }


  const payment_methods = typeof category.organization.payment_methods === "string" && category.organization.payment_methods.length > 0  ? JSON.parse(category.organization.payment_methods) : []

  console.log(category?.appointment)
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       {/* <h2 className='quicklineminititle'>Get Ticket</h2> */}
       </div>
       <span>{t('You have successfully created a ticket')}</span>
       <div  ref={targetRef} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
      <span style={{fontSize: 20, marginTop: 10}}>{t('Appointment No')}</span>
      <span style={{fontSize: 20, fontWeight: 800}}>{category?.appointment?.number}</span>
      <span style={{fontSize: 16}}>{t('You have created your ticket at:')} {moment(category?.appointment?.createdAt).format('DD/MM/YYYY')}</span>
      <span style={{fontSize: 16}}>{t('Booked for')}: {moment(category?.appointment?.start_time).format('DD/MM/YYYY')}</span>
      <span style={{fontSize: 16}}>{t('Time')}: {moment(category?.appointment?.start_time).format('HH:mm A')} - {moment(category?.appointment?.end_time).format('HH:mm A')}</span>
      <span style={{fontSize: 16, fontWeight: 'bold'}}>{category?.appointment?.language === 'kiswahili' ? category?.appointment?.service?.nameswahili : category?.appointment?.service?.name}</span>
{!category?.appointment?.insurance && <span style={{fontSize: 16, fontWeight: 'bold'}}>{t('Payment status:')} {t(`${category?.appointment?.paymentstatus}`)}</span>}
{category?.appointment?.insurance && <span style={{fontSize: 16, fontWeight: 'bold'}}>{t(`Payment status: Insurance Verification is pending`)}</span>}
       </div>
       {category?.appointment?.paymentstatus !== 'paid' && !category?.appointment?.insurance &&  <span style={{fontSize: 16, textAlign: 'center', marginTop: 30}}>{t('Please pay for your appointment using the following payment methods')}</span>}
      {Array.isArray(payment_methods) && !category?.appointment?.insurance &&  payment_methods.map((pay) => {
                 if (pay.hasOwnProperty('mpesa')) {
                  // Render the "mpesa" value if it exists
                 return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
        <img src={mpesaimg} alt='mpesa' style={{objectFit: 'contain', width: 100}} />
        <span style={{marginTop: 10}}>Lipa number: {pay.mpesa}</span>
      </div>
                 )}else if (pay.hasOwnProperty('tigopesa')) {
                    // Render the "mpesa" value if it exists
                   return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
        <img src={airtelmoneyimg} alt='mpesa' style={{objectFit: 'contain', width: 60}} />
        <span style={{marginTop: 10}}>Lipa number: {pay.tigopesa}</span>
      </div>
                   )}else if (pay.hasOwnProperty('airtelmoney')) {
                    // Render the "mpesa" value if it exists
                   return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
        <img src={tigopesaimg} alt='mpesa' style={{objectFit: 'contain', width: 60}} />
        <span style={{marginTop: 10}}>Lipa number: {pay.airtelmoney}</span>
      </div>
                   )}else if (pay.hasOwnProperty('halopesa')) {
                    // Render the "mpesa" value if it exists
                   return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
        <img src={halopesa} alt='mpesa' style={{objectFit: 'contain', width: 60}} />
        <span style={{marginTop: 10}}>Lipa number: {pay.halopesa}</span>
      </div>
                   )} })}
      <Button onClick={() => navigate('/organizations')} variant="primary" style={{width: '100%', marginBottom: 20, marginTop: 50}}> {t('Go back')}</Button>
      <Button onClick={() => generatePDF(targetRef, {filename: 'ticket.pdf'})} variant="primary" style={{width: '100%', marginBottom: 50}}> {t('Download ticket')}</Button>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Successscreen;
// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const LOADING = 'LOADING';
export const PHONE = 'PHONE';
export const USERINFO = 'USERINFO';
export const LANGUAGE = 'LANGUAGE';
export const TIMESLOTS = 'TIMESLOTS';
export const TOKEN = 'TOKEN';
export const DEVICETYPE = 'DEVICETYPE';
export const SELECTEDAPPOINTMENT = 'SELECTEDAPPOINTMENT';
export const RECENTAPPOINTMENTS = 'RECENTAPPOINTMENTS';
export const VISITS = 'VISITS';
export const SELECTEDDATE = 'SELECTEDDATE';
export const APPOINTMENT = 'APPOINTMENT';
export const LOGIN = 'LOGIN';
export const GETINSURANCES = 'GETINSURANCES';
export const SELECTEDINSURANCE = 'SELECTEDINSURANCE';
export const GETDOCTORSSSUCCESS = 'GETDOCTORSSSUCCESS';
export const SELECTEDDOCTOR = 'SELECTEDDOCTOR';
export const TYPE = 'TYPE';
export const SELECTEDCAT = 'SELECTEDCAT';
export const GETORGANIZATION = 'GETORGANIZATION';
export const SELECTEDTYPE = 'SELECTEDTYPE';
export const SUBMITTICKET = 'SUBMITTICKET';
export const ERROR = 'ERROR';
export const GETORGANIZATIONSSUCCESS = 'GETORGANIZATIONSSUCCESS';
export const GETINSURANCESUCCESS = 'GETINSURANCESUCCESS';
export const SUCCESSCHANGEPASSWORD = 'SUCCESSCHANGEPASSWORD';
export const CLEARSUCCESSAUTH = 'CLEARSUCCESSAUTH';
export const GETWEEKREPORTSSUCCESS = 'GETWEEKREPORTSSUCCESS';
export const GETTICKETSSUCCESSALL = 'GETTICKETSSUCCESSALL';
export const TICKETUPDATESTATUS = 'TICKETUPDATESTATUS';
export const GETSTAFFPERFORMANCESSUCCESS = 'GETSTAFFPERFORMANCESSUCCESS';
export const CLEARLINKSUCCESS = 'CLEARLINKSUCCESS';
export const LOGOUT = 'LOGOUT';
export const CLEARCURRENT = 'CLEARCURRENT';
export const CREATECATEGORYSUCCESS = 'CREATECATEGORYSUCCESS';
export const GETCATEGORYSUCCESS = 'GETCATEGORYSUCCESS';
export const UPDATECATEGORYSUCCESS = 'UPDATECATEGORYSUCCESS';
export const DELETECATEGORYSUCCESS = 'DELETECATEGORYSUCCESS';
export const CLEARSUCCESS = 'CLEARSUCCESS';
export const SUCCESSMESSAGE = 'SUCCESSMESSAGE';
export const CLEARSUCCESSMESSAGE = 'CLEARSUCCESSMESSAGE';
export const CREATESTAFFSUCCESS = 'CREATESTAFFSUCCESS';
export const CREATECOUNTERSUCCESS = 'CREATECOUNTERSUCCESS';
export const UPDATECOUNTERSUCCESS = 'UPDATECOUNTERSUCCESS';
export const DELETECOUNTERSUCCESS = 'DELETECOUNTERSUCCESS';
export const GETCOUNTERSUCCESS = 'GETCOUNTERSUCCESS';
export const UPDATESTAFFSUCCESS = 'UPDATESTAFFSUCCESS';
export const DELETESTAFFSUCCESS = 'DELETESTAFFSUCCESS';
export const GETSTAFFSUCCESS = 'GETSTAFFSUCCESS';
export const APPENDTICKET = 'APPENDTICKET';
export const CREATETICKETSUCCESS = 'CREATETICKETSUCCESS';
export const GETTICKETSUCCESS = 'GETTICKETSUCCESS';
export const DELETETICKETSUCCESS = 'DELETETICKETSUCCESS';
export const UPDATETICKETSUCCESS = 'UPDATETICKETSUCCESS';
export const GETTICKETSSUCCESS = 'GETTICKETSSUCCESS';
export const CURRENTTICKET = 'CURRENTTICKET';
export const SETTIMER = 'SETTIMER';
export const CLEARTICKET = 'CLEARTICKET';
export const UPDATECURRENTCOUNTER = 'UPDATECURRENTCOUNTER';
export const UPDATETIMER = 'UPDATETIMER';
export const GETTODAYREPORTSSUCCESS = 'GETTODAYREPORTSSUCCESS';
export const GETMONTHREPORTSSUCCESS = 'GETMONTHREPORTSSUCCESS';
export const GETCATEGORYREPORTSSUCCESS = 'GETCATEGORYREPORTSSUCCESS';
export const GETAPPOINTMENTSSUCCESS = 'GETAPPOINTMENTSSUCCESS';
export const CREATEORG = 'CREATEORG';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { createTicket, selectedCat, selectedInsurance } from '../../store/actions/categoryActions';
import { useTranslation } from 'react-i18next';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

function Insurance({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const navigate = useNavigate()



  const onSubmit = (cat) => {
    if(category.mobile === false && category.type === 'ticket'){
      dispatch(createTicket({service: category.selectedcategory, officephonenumber: category.organization.officephonenumber, client_id: auth.userdata?.user?._id, date: new Date(), name: auth.userdata.user.full_name, mobile: auth.userdata.user.telephone_number, organization: category.organization._id, clienttype: 'insurance', address: auth.userdata.user.local_address, age: auth.userdata.user.birthdate, language: category.language, insurancephoto: '', insurance: cat}, auth.userdata.auth_token, navigate));
    }else {
       dispatch(selectedInsurance(cat, category.type, navigate))
    }
   
  }
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       <h2 className='quicklineminititle'>{t('Choose your Insurance')}</h2>
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {Array.isArray(category?.insurances) && category?.insurances?.map((cat) => {
        return (
          <Button onClick={() => onSubmit(cat?._id)} variant="outline-primary" key={cat?._id} style={{width: 250, marginTop: 20}}>{cat?.name}</Button>
        )
      })}

       </div>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'submitticket'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
      
    </>
  );
}

export default Insurance;
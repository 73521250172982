import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { getCategories, getOrganizations } from '../../store/actions/categoryActions';
import { Power } from 'react-bootstrap-icons';
import { logout } from '../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { QuestionCircle } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';

function Organizations({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const [organization, setOrganization] = useState('');
  const {t} = useTranslation();
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const onSubmit = () => {
   if(organization){
    const choosenorg = category?.organizations?.find((org) => org?._id === organization);
    dispatch(getCategories(choosenorg, auth.token, navigate));
   }else{
    alert('Please choose an organization')
   }
  }

  useEffect(() => {
    dispatch(getOrganizations(navigate));
  }, [])
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
      <span onClick={() => {dispatch(logout(navigate))}} style={{marginTop: 20}}><Power size={20}/> Log out</span>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       <h2 className='quicklineminititle'>{t("Choose your Organization")}</h2>
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
       <Form.Select size="md" style={{width: '100%'}} value={organization} placeholder={t("Choose Organization")} onChange={(e) => {console.log(e.target.value);setOrganization(e.target.value)}}>
       <option value="" disabled>Select an option</option>
       {Array.isArray(category.organizations) && category?.organizations?.map((org) => {
        return (
            <option value={org?._id} key={org?._id}>{org?.name}</option>
        )
       })}
      </Form.Select>
      <Button variant="primary" style={{width: '100%', marginTop: 20}} onClick={() => onSubmit()}>Endelea</Button>
      <Alert variant="danger" style={{width: '100%', marginTop: 100, backgroundColor: '#0080FF', border: 0}}>
        <Alert.Heading style={{color: '#fff'}}>{t("Information")}</Alert.Heading>
        <p style={{color: '#fff'}}>
        {t("If you want to reschedule your appointment please click the appointment button from the bottom right of the app")}
        </p>
      </Alert>
       </div>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'getorganizations' || category.loading === 'getcategories'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
   <div onClick={handleShow} style={{position: 'absolute', top: 10, right: 20, width: 100, height: 50, borderRadius: 30, backgroundColor: '#0080FF', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <span style={{color: '#fff'}}>{t("Support")}</span>
   </div>
   <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Information")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>  {t("Experiencing issues with our app? Call our support hotline at +255 753 909 090. We're here to assist you 24/7!")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Organizations;
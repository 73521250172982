import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Dropdown, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { createTicket, selectedCat, selectedType } from '../../store/actions/categoryActions';
import { useTranslation } from 'react-i18next';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

function Insurancechoice({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const {t} = useTranslation();
  const navigate = useNavigate();

  console.log(category);

  const onSubmit = (type) => {
    if(category.mobile === false && type === 'cash' && category.type === 'ticket'){
      dispatch(createTicket({service: category.selectedcategory, officephonenumber: category.organization.officephonenumber, client_id: auth.userdata?.user?._id, date: new Date(), name: auth.userdata.user.full_name, mobile: auth.userdata.user.telephone_number, organization: category.organization._id, clienttype: 'cash', address: auth.userdata.user.local_address, age: auth.userdata.user.birthdate, language: category.language, insurancephoto: '', insurance: ''}, auth.userdata.auth_token, navigate));
    }else{
    dispatch(selectedType(type, category.type, navigate))
    }
   
  }
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       {/* <h2 className='quicklineminititle'>Get Ticket</h2> */}
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
       <Button onClick={() => onSubmit('cash')} variant="primary" style={{width: '100%', marginBottom: 50}}>{t('Non insurance client')}</Button>
       <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}><Dropdown.Divider style={{backgroundColor: 'grey', height: 1}} /><span>{t("or")}</span><Dropdown.Divider style={{backgroundColor: 'grey', height: 1}} /></div>
       </div>
       <Button onClick={() => onSubmit('insurance')} variant="primary" style={{width: '100%', marginTop: 50}}> {t('Insurance client')}</Button>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'submitticket'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
      
    </>
  );
}

export default Insurancechoice;
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Card, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { createVisitTicket, recentAppointments, recentCalls, selectedAppointment, selectedCat, selectedDoctor } from '../../store/actions/categoryActions';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function Visits({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(recentCalls(auth.token, navigate))
  }, [])

  console.log(auth)

  const submit = (item) => {
    if(item.status == 'active'){
        if(item?.insurance){
          dispatch(createVisitTicket({service: item.service, officephonenumber: item.organization.officephonenumber, client_id: auth.userdata?.user?._id, date: new Date(), name: auth.userdata.user.full_name, mobile: auth.userdata.user.telephone_number, organization: item?.organization?.id, clienttype: item?.clienttype, address: auth.userdata.user.local_address, age: auth.userdata.user.birthdate, language: category.language, insurancephoto: '', insurance: item?.insurance, days: item?.days, daysvisited: item?.daysvisited, visitid: item?._id}, auth.token, item?.organization, navigate))
        }else{
          dispatch(createVisitTicket({service: item.service, officephonenumber: item.organization.officephonenumber, client_id: auth.userdata?.user?._id, date: new Date(), name: auth.userdata.user.full_name, mobile: auth.userdata.user.telephone_number, organization: item?.organization?._id, clienttype: 'cash', address: auth.userdata.user.local_address, age: auth.userdata.user.birthdate, language: category.language, insurancephoto: '', insurance: '', days: item?.days, daysvisited: item?.daysvisited, visitid: item?._id}, auth.token,  item?.organization, navigate))
        }
      }else{
        alert(`${t('Your visits have Expired or you have reached number of days')}`);

      }
    // dispatch(selectedAppointment({service: item.service, organization: item.organization._id, clienttype: item.clienttype, doctor: item.doctor, from: 'recent', appointment: item._id}, navigate))
  }

  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 50}}/>
       <h2 className='quicklineminititle'>Chagua Daktari wako</h2>
       </div> */}
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 50}}>
      {Array.isArray(category?.visits) && category?.visits?.map((cat) => {
        return (
          <Card style={{width: '100%', marginBottom: 20}}>
          {/* <Card.Header as="h5">Appointment Number: {cat?.number}</Card.Header> */}
          <Card.Body>
            <Card.Text> booked at {moment(cat?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</Card.Text>
            <Card.Text>
            Organization: {cat?.organization?.name}
            </Card.Text>
            <Card.Text>
            Number of days: {cat?.days}
            </Card.Text>
            <Card.Text>
            Visits used: {cat?.daysvisited}
            </Card.Text>
            <Card.Text> status: {cat?.status}</Card.Text>
            <Button disabled={cat?.status !== 'active'} onClick={() => submit(cat)} style={{right: 0}} variant="primary">Create Ticket</Button>
          </Card.Body>
        </Card>
        )
      })}
       </div>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'submitticket'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
      
    </>
  );
}

export default Visits;
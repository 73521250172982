import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../store/actions/authActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import { enUS, es } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import { useTranslation } from 'react-i18next';

function Personalinformation({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const {t} = useTranslation()

  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
       <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
       <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 80}}/>
       <h2 className='quicklineminititle' style={{fontSize: 16}}>{t("Enter your basic details to complete registration")}</h2>
       </div>
       {customization?.error?.type === 'personalinformation' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
            address: '',
            password: '',
            fname: '',
            lname: '',
            age: '',
            policy: '',
            submit: null
        }}
        validationSchema={Yup.object().shape({
            address: Yup.string().max(255).required('Address is required'),
            age: Yup.date().required('Date is required').max(new Date(), 'Date must be in the past'),
            password: Yup.string().max(255).required('Password is required'),
            fname: Yup.string().max(255).required('First name is required'),
            lname: Yup.string().max(255).required('Last name is required'),
            policy: Yup.string().max(255).required('please read and accept the privacy policy')
        })}
        onSubmit={async (values) => {
          console.log(values);
            dispatch(
              register({
                user_id: auth.userinfo.user_id,
                full_name: `${values.fname} ${values.lname}`,
                address: values.address,
                age: values.age,
                password: values.password,
                user_type: 'client'
              })
            );
          }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
        <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("First name")}
          aria-label="firstname"
          type="text"
          value={values.fname}
          name="fname"
          onBlur={handleBlur}
          onChange={(e) => {console.log(e);handleChange(e)}}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.fname && errors.fname}
        />
        {touched.fname && errors.fname && (
         <Form.Control.Feedback type="invalid">
              {errors.fname}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("Last name")}
          aria-label="lastname"
          type="text"
          value={values.lname}
          name="lname"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.lname && errors.lname}
        />
        {touched.lname && errors.lname && (
         <Form.Control.Feedback type="invalid">
              {errors.lname}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("Address")}
          aria-label="address"
          type="text"
          value={values.address}
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.address && errors.address}
        />
        {touched.address && errors.address && (
         <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
      <DatePicker className="custom-datepicker"  name='age' date={values.age}  onDateChange={(e) => {handleChange({target: {value: e, name: 'age'}, type: 'change'})}} locale={enUS}>
        {({ inputProps, focused }) => (
          <>
           <Form.Control
           value={values.age}
           name='age'
           className={'input' + (focused ? ' -focused' : '')}
           {...inputProps} 
         />         
          </>
        )}
      </DatePicker>
      {touched.age && errors.age && (
          <Form.Control.Feedback type="invalid">
               {errors.age}
             </Form.Control.Feedback>
         )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("Password")}
          type='password'
          name="password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label="password"
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.password && errors.password}
        />
          {touched.password && errors.password && (
         <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
              <Form.Check
          type={'checkbox'}
          id={`policy`}
          name="policy"
          value={values.policy}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label="policy-policy"
          aria-describedby="basic-addon1"
          isInvalid={touched.policy && errors.policy}
          label={(
            <>
              I have read and agree to the{' '}
              <a href="https://quickline.tech" target="_blank">
                privacy policy
              </a>{' '}
              of quickline.tech
            </>
          )}
        />
          {touched.policy && errors.policy && (
         <Form.Control.Feedback type="invalid">
              {errors.policy}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button disabled={auth.loading === 'personalinformation'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'personalinformation' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : `${t("Submit")}`}</Button>
           </Form>
         )}
      </Formik>
       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Personalinformation;
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { selectedCat, selectedDate } from '../../store/actions/categoryActions';
import { isSameDay } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { DatePickerCalendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import { useState } from 'react';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

function Calender({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
  const [date, setDate] = useState()
  const {t} = useTranslation();
  const navigate = useNavigate();


  const onChooseDoctor = () => {
    // navigation.navigate('timeslots', {date: selected, service: params.service});
   if(date){
    const selecteddate = moment(date).add(1, 'day').tz('Africa/Dar_es_Salaam').toISOString();
    dispatch(selectedDate(selecteddate, category.selectedcategory, category.organization._id, navigate))
   }else{
    alert(`${t('Please choose a valid date')}`)
  }
  }
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 50}}/>
       <h2 className='quicklineminititle'>{t("Choose your date of appointment")}</h2>
       </div>
       <div className='calender-outside' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 50}}>
       <DatePickerCalendar minimumDate={new Date()} date={date} onDateChange={setDate} locale={enGB} />
       </div>
       <Button variant="primary" style={{width: '100%', marginTop: 20}} onClick={() => onChooseDoctor()}> {t('Get time slots for this date')}</Button>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'appointments'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
      
    </>
  );
}

export default Calender;
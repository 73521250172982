import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import i18n from '../../utils/i18n';
import { selectlanguage } from '../../store/actions/categoryActions';
import { useTranslation } from 'react-i18next';

function Login({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
  const [language, setLanguage] = useState('');
  const {t} = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (val) => {
    if(val === 'english'){
        i18n.changeLanguage('en');
    }else{
        i18n.changeLanguage('sw');
    }
   dispatch(selectlanguage(val))
}
  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
     
       {/* <h2 className='quicklinetitle'>Quickline</h2> */}
       <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 80}}/>
       <h2 className='quicklineminititle'>{t("Enter Client details to Log in")}</h2>
       </div>
      
       {customization?.error?.type === 'login' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
          phonenumber: '+255',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          phonenumber: Yup.string()
            .required('Phone number is required')
            .test('is-valid-phone', 'Phone number is invalid', (value) => {
              return isValidPhoneNumber(value || '');
            }),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values) => {
          const removedPrefixPhoneNumber = values.phonenumber.substring(4);
          dispatch(login({ country_code: '255', mobile_number: removedPrefixPhoneNumber, password: values.password }, navigate));
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
              <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("Phone number")}
          aria-label="phonenumber"
          value={values.phonenumber}
          style={{width: '100% !important'}}
          name="phonenumber"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.phonenumber && errors.phonenumber}
        />
        {touched.phonenumber && errors.phonenumber && (
         <Form.Control.Feedback type="invalid">
              {errors.phonenumber}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("Password")}
          type='password'
          name="password"
          value={values.password}
          style={{width: '100% !important'}}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label="password"
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.password && errors.password}
        />
          {touched.password && errors.password && (
         <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button style={{width: '100% !important'}} disabled={auth.loading === 'login'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'login' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : `${t("Submit")}`}</Button>
           </Form>
         )}
      </Formik>
      <Link to='/auth/mobile'><p style={{marginTop: 50}}>{t("Don't have an account?")} {t("Register")}</p></Link>
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
       <Form.Select size="md" style={{width: '100%'}} value={category.language} placeholder='Choose Organizations' onChange={(e) => changeLanguage(e.target.value)}>
       <option value="" disabled>Choose Language</option>
      <option value={'kiswahili'}>Kiswahili</option>
      <option value={'english'}>English</option>
      </Form.Select>
      </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Login;
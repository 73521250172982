import React from "react";
import { House,  Calendar4Week, People } from 'react-bootstrap-icons';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";


const MinimalLayout = (props) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
    return (
        <div style={{width: '100%', heght: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff'}}>
          {props.children}
        {auth.token &&   <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 60, border: '1px solid grey', position: 'fixed', bottom: 0, left: 0, backgroundColor: '#fff'}}>
          <div onClick={() => {
            navigate('/organizations')
          }} style={{width: '50%', height: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
          <House size={20} color="#0080FF"/>
            <span style={{color: '#0080FF'}}>Home</span>
          </div>
          <div onClick={() => {
            navigate('/recentappointments')
          }}  style={{width: '50%', height: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
          <Calendar4Week size={20} color='#0080FF'/>
            <span style={{color: '#0080FF'}}>Appointment</span>
          </div>
          <div onClick={() => {
            navigate('/calls')
          }}  style={{width: '50%', height: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
          <People size={20} color='#0080FF'/>
            <span style={{color: '#0080FF'}}>Visits</span>
          </div>
          </div>}
        </div>
    )
}


export default MinimalLayout;
// action - state management
import { APP_URL } from '../../constants/apis';
import * as actionTypes from '../actions';
import axios from 'axios';

export const createCategory = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createcategory' });
  try {
    await axios.post(`${APP_URL}organization/services/create`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Category created successfully' });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const clearcategorysuccess = () => async (dispatch) => {
  dispatch({type: actionTypes.CLEARSUCCESS})
}

export const updateCategory = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatecategory' });
  try {
    await axios.post(`${APP_URL}organization/services/update`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.UPDATECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Category updated successfully' });

    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const daleteCategory = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'deletecategory' });
  try {
    await axios.post(`${APP_URL}organization/services/delete`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.DELETECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Category deleted successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getCategories = (organization_id, token, navigate) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'getcategories' });
  try {
    await axios.get(`${APP_URL}insurance/list?organization=${organization_id?._id}`, {headers}).then((res) => {
      dispatch({ type: actionTypes.GETINSURANCES, payload: res.data.insurance });
    });
    await axios.get(`${APP_URL}organization/services/list?organization=${organization_id?._id}`, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETORGANIZATION, payload: organization_id });
      dispatch({ type: actionTypes.GETCATEGORYSUCCESS, payload: res.data });
      navigate('/category');
      // window.location.replace('/category');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    if(error?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const createTicket = (data, token, navigate) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'submitticket' });
  try {
    await axios.post(`${APP_URL}tickets/create`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.SUBMITTICKET, payload: res.data });
      dispatch({ type: actionTypes.LOADING, payload: false });
      navigate('/ticketsuccess');
      // window.location.replace('/ticketsuccess');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    if(error?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({ type: actionTypes.SUBMITTICKET, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const createVisitTicket = (data, token, org, navigate) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'submitticket' });
  try {
    await axios.post(`${APP_URL}tickets/visitcreate`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETORGANIZATION, payload: org });
      dispatch({ type: actionTypes.SUBMITTICKET, payload: res.data });
      dispatch({ type: actionTypes.LOADING, payload: false });
      navigate('/ticketsuccess');
      // window.location.replace('/ticketsuccess');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    if(error?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({ type: actionTypes.SUBMITTICKET, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const selectedCat = (categ, navigate) => async (dispatch) => {
  dispatch({type: actionTypes.SELECTEDCAT, payload: categ})
  navigate('/choicescreen')
  // window.location.replace('/choicescreen');
}

export const selectedInsurance = (categ, type, navigate) => async (dispatch) => {
  dispatch({type: actionTypes.SELECTEDINSURANCE, payload: categ})
  if(type === 'ticket'){
    navigate('/qrcode');
  }else {
    navigate('/calender');
  }
}

export const selectedDate = (date, service, organization, navigate) => async (dispatch) => {
  dispatch({type: actionTypes.SELECTEDDATE, payload: date})
  try {
    dispatch({type: actionTypes.LOADING, payload: 'appointments'});
    await axios.get(`${APP_URL}appointments/list?date=${date}&service=${service}&organization=${organization}`).then((data) => {
      dispatch({type: actionTypes.LOADING, payload: null});
      dispatch({type: actionTypes.TIMESLOTS, payload: data.data.appointments});
      navigate('/timeslots');
      // window.location.replace('/timeslots');
      // navigate('timeslots', {service, date, clienttype, doctor, from, appointment, insurance});
    }).catch((err) => {
      if(err?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
        alert('Session Expired, please login to continue')
        dispatch({ type: actionTypes.LOGOUT });
        navigate('/auth/login');
      }
      dispatch({type:actionTypes.LOADING, payload: null});
    })
}catch(err){
  dispatch({ type: actionTypes.LOADING, payload: false });
}}


export const selectedDoctor = (categ, navigate) => async (dispatch) => {
  dispatch({type: actionTypes.SELECTEDDOCTOR, payload: categ})
  navigate('/insurancechoice');
  // window.location.replace('/insurancechoice');
}

export const selectlanguage = (categ) => async (dispatch) => {
  dispatch({type: actionTypes.LANGUAGE, payload: categ})
}

export const selectedAppointment = (categ, navigate) => async (dispatch) => {
  dispatch({type: actionTypes.SELECTEDAPPOINTMENT, payload: categ})
  navigate('/calender');
}


export const bookAppointment =  (service, officephonenumber, time, slot_id, client_phone, client_name, organization, client_id, clienttype, image, doctor, token, language, insurance, navigate) => async (dispatch) => {
  // console.log()
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
 
  try {
    dispatch({type:  actionTypes.LOADING, payload: 'appointment'});
    const response = await axios.post(`${APP_URL}appointments/create`, {service, officephonenumber, client_id:  client_id, end_time: time.to,start_time: time.from, slot_id, client_phone, name: client_name, organization, clienttype, insurancephoto: image, doctor, language, insurance},  {headers});
    dispatch({type: actionTypes.APPOINTMENT, payload: response.data.appointment});
    dispatch({type:  actionTypes.LOADING, payload: null});
    navigate('/successscreen')
    // navigate('successscreen', {appointment: response.data.appointment});
  } catch (err) {
    console.log(err?.response?.data || err);
    if(err?.response?.data?.message === 'appointment.appointment_slot_already_booked'){
      if(language === 'kiswahili'){
       alert("Muda uliochagua unamtumiaji mwingine,Tafadhali chagua muda mwingine")
      }else{
       alert("Timeslot already booked please choose another one")
      }
    }

    if(err?.response?.data?.message === 'appointment.appointment_time_has_passed'){
      if(language === 'kiswahili'){
       alert("Muda uliochagua umeshapita , tafadhali chagua muda mwingine")
      }else{
       alert("The chosen time has already passed; please select another time.")
      }
    }

    if(err?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({type:  actionTypes.LOADING, payload: null});
  }
};

export const recentAppointments =  (token, navigate) => async (dispatch) => {
  // console.log()
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
 
  try {
    dispatch({type:  actionTypes.LOADING, payload: 'recentappointment'});
    const response = await axios.get(`${APP_URL}appointments/client`,   {headers});
    dispatch({type: actionTypes.RECENTAPPOINTMENTS, payload: response.data.appointments});
    dispatch({type:  actionTypes.LOADING, payload: null});
    // navigate('successscreen', {appointment: response.data.appointment});
  } catch (err) {
    console.log(err?.response?.data || err);
    if(err?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({type:  actionTypes.LOADING, payload: null});
  }
};

export const recentCalls =  (token, navigate) => async (dispatch) => {
  // console.log()
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
 
  try {
    dispatch({type:  actionTypes.LOADING, payload: 'recentappointment'});
    const response = await axios.get(`${APP_URL}organization/uservisits`,   {headers});
    dispatch({type: actionTypes.VISITS, payload: response.data.visits});
    dispatch({type:  actionTypes.LOADING, payload: null});
    // navigate('successscreen', {appointment: response.data.appointment});
  } catch (err) {
    console.log(err?.response?.data || err);
    if(err?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({type:  actionTypes.LOADING, payload: null});
  }
};

export const updateAppointment = ( time, slot_id, appointment, token, language, navigate) => async dispatch => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  
  try {
    dispatch({type: actionTypes.LOADING, payload: 'appointment'});
    const response = await axios.post(`${APP_URL}appointments/updatedate`, {end_time: time.to,start_time: time.from, slot_id, ticket: appointment, language},  {headers});
    dispatch({type: actionTypes.APPOINTMENT, payload: response.data.appointment});
    navigate('/successscreen')
    dispatch({type: actionTypes.LOADING, payload: null});
    // navigate('successscreen', {appointment: response.data.appointment});
  } catch (err) {
    console.log(err?.response?.data || err);
    if(err?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({type: actionTypes.LOADING, payload: null});
  }
};



export const selectType = (categ, mobile) => async (dispatch) => {
  dispatch({type: actionTypes.TYPE, payload: categ})
  dispatch({type: actionTypes.DEVICETYPE, payload: mobile})
}


export const selectedType = (categ, type, navigate) => async (dispatch) => {
  dispatch({type: actionTypes.SELECTEDTYPE, payload: categ})
  if(categ === 'insurance'){
    navigate('/insurance')
    // window.location.replace('/insurance');
  }else if(type === 'ticket'){
    navigate('/qrcode')
    // window.location.replace('/qrcode');
  } else if(type === 'appointment'){
    navigate('/calender')
    // window.location.replace('/calender');
  }
}




export const getOrganizations = (navigate) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'getorganizations' });
  try {
    await axios.get(`${APP_URL}organizations/list`).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETORGANIZATIONSSUCCESS, payload: res.data.organizations });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.message);
    if(error?.response?.data?.message === "auth.auth-token_header_is_invalid_or_expired"){
      alert('Session Expired, please login to continue')
      dispatch({ type: actionTypes.LOGOUT });
      navigate('/auth/login');
    }
    dispatch({ type: actionTypes.GETORGANIZATIONSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
}

export const getDoctors = (organization, service, navigate) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'getdoctors' });
  try {
    await axios.get(`${APP_URL}doctors/list?organization=${organization}&service=${service}`).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETDOCTORSSSUCCESS, payload: res.data.members });
      navigate('/doctors');
      // window.location.replace('/doctors');
    });
  } catch (error) {
    alert(`${JSON.stringify(error?.response?.data?.message)}`)
    dispatch({ type: actionTypes.GETDOCTORSSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
}



import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { verifyphonenumber } from '../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

function Verifyphone({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
const {t} = useTranslation()
  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
     
       {/* <h2 className='quicklinetitle'>Quickline</h2> */}
       <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 200}}/>
       <h2 className='quicklineminititle'>{t('Enter verification code to verify')}</h2>
       {customization?.error?.type === 'verifyphone' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
          code: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required('Code is required')
        })}
        onSubmit={async (values) => {
            dispatch(verifyphonenumber({ telephone_number: auth.phonenumber.telephone_number, verification_code: values.code }, navigate));
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
              <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t('Verification code')}
          aria-label="code"
          value={values.code}
          name="code"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.code && errors.code}
        />
        {touched.code && errors.code && (
         <Form.Control.Feedback type="invalid">
              {errors.code}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button disabled={auth.loading === 'verifyphone'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'verifyphone' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : `${t("Submit")}`}</Button>
           </Form>
         )}
      </Formik>
       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Verifyphone;
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { mobileSend } from '../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

function Mobile({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
       <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
       <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 80}}/>
       <h2 className='quicklineminititle'>{t('Enter mobile phone to verify')}</h2>
       </div>
       {customization?.error?.type === 'mobileinput' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
          phonenumber: '+255',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          phonenumber: Yup.string()
            .required('Phone number is required')
            .test('is-valid-phone', 'Phone number is invalid', (value) => {
              return isValidPhoneNumber(value || '');
            })
        })}
        onSubmit={async (values) => {
          const removedPrefixPhoneNumber = values.phonenumber.substring(4);
          dispatch(mobileSend({ country_code: '255', mobile_number: removedPrefixPhoneNumber }, navigate));
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
              <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder={t("Phone number")}
          aria-label="phonenumber"
          value={values.phonenumber}
          name="phonenumber"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.phonenumber && errors.phonenumber}
        />
        {touched.phonenumber && errors.phonenumber && (
         <Form.Control.Feedback type="invalid">
              {errors.phonenumber}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button disabled={auth.loading === 'mobileinput'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'mobileinput' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> :  `${t("Submit")}`}</Button>
           </Form>
         )}
      </Formik>
       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Mobile;
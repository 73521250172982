import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Dropdown, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { getDoctors, selectType, selectedCat } from '../../store/actions/categoryActions';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

function Choicescreen({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (cat) => {
    dispatch(selectType('ticket', isMobile))
    navigate('/insurancechoice');
    // window.location.replace('/insurancechoice')
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       <h2 className='quicklineminititle'>{t("Get a Ticket")}</h2>
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
       <Button onClick={() => onSubmit()} variant="primary" style={{width: '100%', marginBottom: 50}}>{isMobile ? `${t('Scan Qr Code to get Ticket')}` : `${t('Book a Ticket')}`}</Button>
       <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}><Dropdown.Divider style={{backgroundColor: 'grey', height: 1}} /><span>{t("or")}</span><Dropdown.Divider style={{backgroundColor: 'grey', height: 1}} /></div>
       </div>
       <span style={{marginTop: 50, marginBottom: 20}}>{t('Book an Appointment')}</span>
       <Button  onClick={() => dispatch(getDoctors(category?.organization?._id, category?.selectedcategory, navigate))} variant="primary" style={{width: '100%', marginBottom: 50}}>{t('Book an Appointment')}</Button>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'getdoctors'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
      
    </>
  );
}

export default Choicescreen;
// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  createlinksuccess: false,
  updatelinksuccess: false,
  deletelinksuccess: false,
  createticketsuccess: false,
  loading: false,
  ticket: {},
  unsubmittedticket: {},
  tickets: [],
  currentticket: {},
  isRunning: false,
  timer: 0,
  todayreports: [],
  monthreports: [],
  categoryreports: [],
  staffperformance: [],
  appointments: [],
  ticketsall: [],
  weeklyreports: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.GETTICKETSSUCCESSALL:
      return {
        ...state,
        ticketsall: action.payload,
        updatestatussuccess: false
      }
    case actionTypes.TICKETUPDATESTATUS:
      return {
        ...state,
        updatestatussuccess: action.payload
      }
    case actionTypes.GETAPPOINTMENTSSUCCESS:
      return {
        ...state,
        appointments: action.payload
      }
    case actionTypes.UPDATETIMER:
      return {
        ...state,
        timer: 0,
        isRunning: false
      }
    case actionTypes.CLEARCURRENT:
      return {
        ...state,
        timer: 0,
        isRunning: false,
        currentticket: {}
      }
      case actionTypes.GETSTAFFPERFORMANCESSUCCESS:
        return {
          ...state,
          staffperformance: action.payload
        }
      case actionTypes.GETCATEGORYREPORTSSUCCESS:
        return {
          ...state,
          categoryreports: action.payload
        }
      case actionTypes.GETWEEKREPORTSSUCCESS:
        return {
          ...state,
          weeklyreports: action.payload
        }
      case actionTypes.GETMONTHREPORTSSUCCESS:
        return {
          ...state,
          monthreports: action.payload
        } 
    case actionTypes.GETTODAYREPORTSSUCCESS:
      return {
        ...state,
        todayreports: action.payload
      }
    case actionTypes.CLEARTICKET:
      return {
        ...state,
        isRunning: false,
        timer: 0,
        currentticket: {}
      }
    case actionTypes.SETTIMER:
      return {
        ...state,
        timer: state.timer + 1
      }
    case actionTypes.CURRENTTICKET:
      return {
        ...state,
        currentticket: action.payload,
        timer: 0,
        isRunning: true
      }
    case actionTypes.CLEARLINKSUCCESS:
      return {
        ...state,
        createticketsuccess: false,
        ticket: {},
        loading: false,
      }
    case actionTypes.CREATETICKETSUCCESS:
        return {
          ...state,
          createticketsuccess: true,
          ticket: action.payload
        };
    case actionTypes.UPDATETICKETSUCCESS:
        return {
          ...state,
          updatestaffsuccess: action.payload
        };
    case actionTypes.DELETETICKETSUCCESS:
        return {
            ...state,
            deletestaffsuccess: action.payload
          };
    case actionTypes.GETTICKETSUCCESS:
          return {
            ...state,
            ticket: action.payload
          };
    case actionTypes.GETTICKETSSUCCESS:
      return {
          ...state,
          tickets: action.payload
      };
    case actionTypes.APPENDTICKET:
        return {
            ...state,
            unsubmittedticket: {...state.unsubmittedticket, ...action.payload}
        }
    default:
      return state;
  }
};

export default linkReducer;

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Dropdown, Spinner } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import {QrScanner} from '@yudiel/react-qr-scanner';
import { useEffect, useTransition } from 'react';
import { createTicket } from '../../store/actions/categoryActions';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useTranslation } from 'react-i18next';

function Qrcode({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const {t} = useTranslation();

  const onSuccess = e => {
    if(category.selectedtype === 'insurance'){
      dispatch(createTicket({service: category.selectedcategory, officephonenumber: category.organization.officephonenumber, client_id: auth.userdata?.user?._id, date: new Date(), name: auth.userdata.user.full_name, mobile: auth.userdata.user.telephone_number, organization: e, clienttype: 'insurance', address: auth.userdata.user.local_address, age: auth.userdata.user.birthdate, language: category.language, insurancephoto: '', insurance: category.selectedinsurance}, auth.userdata.auth_token));
    }else{
      dispatch(createTicket({service: category.selectedcategory, officephonenumber: category.organization.officephonenumber, client_id: auth.userdata?.user?._id, date: new Date(), name: auth.userdata.user.full_name, mobile: auth.userdata.user.telephone_number, organization: e, clienttype: 'cash', address: auth.userdata.user.local_address, age: auth.userdata.user.birthdate, language: category.language, insurancephoto: '', insurance: ''}, auth.userdata.auth_token));
    }
  };


  return (
    <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <span style={{textAlign: 'center'}}>{t("Go to")} {t("Quickline qrcode")} {t("and scan the QR code")}.</span>
    <QrScanner
          onDecode={(result) => onSuccess(result)}
          onError={(error) => console.log(error?.message)}
      />  
       <CircleSpinnerOverlay
      　　loading={category.loading === 'submitticket'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </div>
  );
}

export default Qrcode;
import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointments, updatedateappointment, updatedateappointment2, updatestatusappointment } from '../../store/actions/linkActions';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Button } from 'react-bootstrap';
import Statusmodal from '../modals/statusmodal';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';
import Timemodal from '../modals/timemodal';
import Reschedulemodal from '../modals/reschedulemodal';


function Appointment() {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.link);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization._id ?? '';
  const [ticket, setTicket] = useState('');
  const [show, setShow] = useState(false);
  const [showtime, setShowtime] = useState(false);
  const [showreschedule, setShowreschedule] = useState(false);
  const [display, setDisplay] = useState("not_paid");
  const [from, setFrom] = useState(new Date());
  const [fromappointment, setFromappointment] = useState(new Date());
  const [to, setTo] = useState(new Date()); 
  const [toappointment, setToappointment] = useState(new Date()); 
  const rows = Array.isArray(appointments.appointments) ? appointments.appointments.map(item => ({ ...item, id: item._id, paid: '-' })) : [];


  useEffect(() => {
    dispatch(getAppointments(organization))
  }, [])

  const onClose = () => {
    setShow(false);
    dispatch(updatestatusappointment(auth.token, ticket, organization, display));
  }

  const onCloseTime = () => {
    setShowtime(false);
    dispatch(updatedateappointment(auth.token, ticket, organization, moment(from).format(), moment(to).format()));
  }

  const onCloseReschedule = () => {
    setShowreschedule(false);
    dispatch(updatedateappointment2(auth.token, ticket, organization, moment(fromappointment).format(), moment(toappointment).format()));
  }

  function capitalizeFirstLetter(str) {
    // Check if the input is not an empty string
    if (str.length === 0) {
      return str;
    }
  
    // Capitalize the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
 
  const columns = [
    // { dataField: '_id', text: 'ID' },
    { dataField: 'client', text: 'Client', formatter: (cell, row, rowIndex, extraData) => (
      <span>{row?.client?.full_name || row?.client_name}</span>
    ), },
    { dataField: 'client', text: 'Mobile', formatter: (cell, row, rowIndex, extraData) => (
      <span>{row?.client?.telephone_number || row?.client_telephone}</span>
    ), },
    { dataField: 'number', text: 'Number' },
    { dataField: 'start_time', text: 'Start Time',  formatter: (cell, row, rowIndex, extraData) => (
      <span>{moment(row?.start_time).format('DD/MM/YYYY HH:mm')}</span>
    ), },
    { dataField: 'end_time', text: 'End Time',  formatter: (cell, row, rowIndex, extraData) => (
      <span>{moment(row?.end_time).format('DD/MM/YYYY HH:mm')}</span>
    ), },
    { dataField: 'appointment_start_time', text: 'App. Time',  formatter: (cell, row, rowIndex, extraData) => (
      <span>{row?.appointment_start_time ? `${moment(row?.appointment_start_time).format('DD/MM/YYYY')} ${moment(row?.appointment_start_time).format('HH:mm')} - ${moment(row?.appointment_end_time).format('HH:mm')}` : '-'}</span>
    ), },
    // { dataField: 'appointment_end_time', text: 'App. End Time',  formatter: (cell, row, rowIndex, extraData) => (
    //   <span>{row?.appointment_end_time ? moment(row?.appointment_end_time).format('DD/MM/YYYY HH:mm') : '-'}</span>
    // ), },
    { dataField: 'reschedule', text: 'Reschedule', formatter: (cell, row, rowIndex, extraData) => (
      <span>{row?.reschedule ? `${capitalizeFirstLetter(row?.reschedule)}` : null}</span>
    ), },
    { dataField: 'clienttype', text: 'Client Type',  formatter: (cell, row, rowIndex, extraData) => (
      <span>{capitalizeFirstLetter(row?.clienttype)}</span>
    ),  },
    { dataField: 'insurance', text: 'Insurance',  formatter: (cell, row, rowIndex, extraData) => (
      row.clienttype === 'insurance' ?  <span>{row?.insurance?.name}</span> : '-'
    ), },
    { dataField: 'status', text: 'Status' },
    { dataField: 'paymentstatus', text: 'Payment Status' },
    { dataField: 'actions', text: 'Actions',  formatter: (cell, row, rowIndex, extraData) => (
      <Button onClick={() => {setDisplay(row?.paymentstatus);setTicket(row._id);setShow(true)} } variant="primary" size='sm'>Update payment status</Button>
    ), },
    { dataField: 'actions', text: 'Duration',  formatter: (cell, row, rowIndex, extraData) => (
      <Button disabled={row?.paymentstatus === 'not_paid' || (row?.appointment_start_time && row?.appointment_end_time)} onClick={() => {setTicket(row._id);setShowtime(true)} } variant="primary" size='sm'>Update attending status</Button>
    ), },
    { dataField: 'actions', text: 'Doctor Reschedule',  formatter: (cell, row, rowIndex, extraData) => (
      <Button disabled={row?.paymentstatus === 'not_paid' || (row?.appointment_start_time && row?.appointment_end_time)} onClick={() => {setTicket(row._id);setShowreschedule(true)} } variant="primary" size='sm'>Reschedule Appointment</Button>
    ), },

  ];
  return (
    <>
    <MainLayout>
    <PhotoProvider>
     <Table fluid={true} buttonType='export' columns={columns} data={rows} title={'Appointments'}/>
     </PhotoProvider>
     <Statusmodal onChangeDisplay={(e) => setDisplay(e.target.value)} show={show} display={display} handleClose={() => setShow(false)} onClose={onClose}/>
     <Timemodal show={showtime} handleClose={() => setShowtime(false)} onClose={onCloseTime} to={to} from={from} setTo={setTo} setFrom={setFrom}/>
     <Reschedulemodal show={showreschedule} handleClose={() => setShowreschedule(false)} onClose={onCloseReschedule} to={toappointment} from={fromappointment} setTo={setToappointment} setFrom={setFromappointment}/>
     <Loadingmodal show={appointments.loading === 'updatestatus'} title='updating status' />
     <Successmodal show={appointments.updatestatussuccess} title='Appointment payment status updated successfully'/>
    </MainLayout>
      
    </>
  );
}

export default Appointment;
import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointments, getTicketsall, updatestatus } from '../../store/actions/linkActions';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Button } from 'react-bootstrap';
import Statusmodal from '../modals/statusmodal';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';
import jsPDF from 'jspdf';
import { Printer } from 'react-bootstrap-icons';




function Tickets() {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.link);
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState("not_paid");
  const auth = useSelector((state) => state.auth);
  const [ticket, setTicket] = useState('');
  const organization = auth?.organizations[0]?.organization._id ?? '';
  const rows = Array.isArray(appointments.ticketsall) ? appointments.ticketsall.map(item => ({ ...item, id: item._id, paid: '-' })) : [];

  const onClose = () => {
    setShow(false);
    dispatch(updatestatus(auth.token, ticket, organization, display));
  }


  useEffect(() => {
    dispatch(getTicketsall(auth.token, organization))
  }, [])

  function capitalizeFirstLetter(str) {
    // Check if the input is not an empty string
    if (str.length === 0) {
      return str;
    }
  
    // Capitalize the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function generatePDF(details) {
    const doc = new jsPDF();
  
    // Add content to the PDF
    doc.setFontSize(25);
    doc.text(`${details.name}`, 105, 30, { align: 'center' });
  
    doc.setFontSize(22);
    doc.text('Queue No.', 105, 60, { align: 'center' });
  
    doc.setFontSize(25);
  //   doc.setFontType('bold');
    doc.text(`${details.number}`, 105, 90, { align: 'center' });
  
    doc.setFontSize(18);
    doc.text(`Arrived ${moment(details.date).format('DD-MM-YYY HH:mm:ss')}`, 105, 120, { align: 'center' });
  
    doc.setFontSize(22);
  //   doc.setFontType('bold');
    doc.text(`${details.service.name}`, 105, 150, { align: 'center' });
  
    doc.setFontSize(16);
    doc.text('There are 0 queuing before you', 105, 180, { align: 'center' });
  
    doc.setFontSize(16);
    doc.text('Your estimated waiting time is 0 minutes', 105, 210, { align: 'center' });
  
    // Save the PDF
    doc.save('queue_ticket.pdf');
  }
 
  const columns = [
    { dataField: 'number', text: 'ID' },
    { dataField: 'name', text: 'Client name' },
    { dataField: 'mobile', text: 'Client phone' },
    { dataField: 'clienttype', text: 'Client type', formatter: (cell, row, rowIndex, extraData) => (
      <span>{capitalizeFirstLetter(row?.clienttype)}</span>
    ), },
    { dataField: 'date', text: 'Date',  formatter: (cell, row, rowIndex, extraData) => (
      <span>{moment(row?.date).format('DD-MM-YYYY HH:mm:ss')}</span>
    ), },
    { dataField: 'insurance', text: 'Insurance',  formatter: (cell, row, rowIndex, extraData) => (
        row.clienttype === 'insurance' ?  <span>{row?.insurance?.name}</span> : '-'
      ), },
    { dataField: 'status', text: 'Status' },
    { dataField: 'paymentstatus', text: 'Payment status', formatter: (cell, row, rowIndex, extraData) => (
        <span>{row.paymentstatus}</span>
      ), },
    { dataField: 'actions', text: 'Actions',  formatter: (cell, row, rowIndex, extraData) => (
        <Button onClick={() => {setDisplay(row?.paymentstatus);setTicket(row._id);setShow(true)} } variant="primary" size='sm'>Update status</Button>
      ), },
      { dataField: 'actions', text: 'Print',  formatter: (cell, row, rowIndex, extraData) => (
        <Button  onClick={() => {generatePDF(row)} } variant="primary" size='sm'><Printer size={20}/></Button>
      ), },

  ];
  return (
    <>
    <MainLayout>
    <PhotoProvider>
     <Table buttonType='export' columns={columns} data={rows} title={'Tickets'}/>
     </PhotoProvider>
     <Statusmodal onChangeDisplay={(e) => setDisplay(e.target.value)} show={show} display={display} handleClose={() => setShow(false)} onClose={onClose}/>
     <Loadingmodal show={appointments.loading === 'updatestatus'} title='updating status' />
     <Successmodal show={appointments.updatestatussuccess} title='Ticket payment status updated successfully'/>
    </MainLayout>
      
    </>
  );
}

export default Tickets;